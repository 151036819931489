import {
  CHECKIN_CONTRACT_ADDRESS_SAI_LONG,
  MEMBERSHIP_CONTRACT_ADDRESS_SAI_LONG,
} from "../constants/contracts";
import { config } from "../constants/chain";
import { connect, getAccount } from "@wagmi/core";
import { DefaultApi, NFTTokens, Profile } from "../api/explorer";
import { hashwalletConnector } from "../hooks/connector";
import { MembershipTokenContractMapping, NFTApi } from "../api/page/generated";

export type MyPageLoaderType = {
  memberships: Membership;
  walletAddress: string;
};
export type Membership = {
  tokenId: number;
  profile: Profile;
  uri: string;
  contractAddress: string;
}[];

export type NFTMetadata = {
  image: string;
  image_data: string;
  external_url: string;
  description: string;
  name: string;
  attributes: Attribute[];
};
export type Attribute = {
  trait_type: string;
  value: string;
};

// arrayDiff関数の実装
export function arrayDiff(
  a: MembershipTokenContractMapping[] | undefined,
  b: MembershipTokenContractMapping[] | undefined
): MembershipTokenContractMapping[] {
  // 配列が未定義またはnullの場合、空の配列として扱う
  const arrayA = Array.isArray(a) ? a : [];
  const arrayB = Array.isArray(b) ? b : [];

  return arrayA.filter(
    (elementA) =>
      !arrayB.some(
        (elementB) =>
          elementA.contract_address === elementB.contract_address &&
          elementA.token_id === elementB.token_id
      )
  );
}

export const getMetadata = async (tokenURI: string) => {
  const metadata = await fetch(tokenURI);
  const _metadata = (await metadata.json()) as NFTMetadata;
  return _metadata;
};

const fetchMembership = async (walletAddress: `0x${string}`) => {
  const membership1 = await new DefaultApi().nftholdersofuserPost({
    Contract: MEMBERSHIP_CONTRACT_ADDRESS_SAI_LONG,
    Address: walletAddress as `0x${string}`,
    PageNo: 0,
    PageSize: 10,
  });

  const _membership = [membership1.data];
  const membership = _membership.filter((m) => m.NFTTokenInfos);
  return membership;
};

export const fetchCheckinNfts = async (
  contractAddress: string,
  tokenId: number
) => {
  const res = await new NFTApi().getCheckinNftsContractAddressTokenId(
    contractAddress,
    tokenId
  );
  return res.data;
};

export const allfetchCheckinNfts = async (
  walletAddress: string
): Promise<MembershipTokenContractMapping[] | undefined> => {
  const res = await new DefaultApi().nfttransactionsofuserPost({
    User: walletAddress,
    Contract: CHECKIN_CONTRACT_ADDRESS_SAI_LONG,
    PageNo: 0,
    PageSize: 1000,
  });

  // MembershipTokenContractMapping[] に変換
  const tokenContracts = res.data.TransactionDetails?.map((tx) => {
    return {
      contract_address: String(tx.Contract),
      token_id: Number(tx.Value),
    } as MembershipTokenContractMapping;
  });

  // tokenidが重複しないtokenContractsを返す
  const uniqueTokenContracts = tokenContracts?.filter(
    (x, i, self) => self.findIndex((t) => t.token_id === x.token_id) === i
  );
  return uniqueTokenContracts;
};

export const MyPageLoader = async (): Promise<MyPageLoaderType | undefined> => {
  try {
    await connect(config, { connector: hashwalletConnector() });
    const walletAddress = getAccount(config).address ?? "";
    if (!walletAddress) {
      throw new Error("walletAddress is not found");
    }
    const _membership = await fetchMembership(walletAddress);
    const membership = flatMembership(_membership);
    membership.push({
      tokenId: -1,
      profile: {
        name: "dummy",
        image: "",
      },
      uri: "",
      contractAddress: "0x",
    });
    return {
      walletAddress: walletAddress,
      memberships: membership,
    };
  } catch (error) {
    console.error(error);
    throw new Error("MyPageLoader failed", { cause: error });
  }
};

const flatMembership = (membership: NFTTokens[]): Membership => {
  return membership.flatMap((m) => {
    return m.NFTTokenInfos!.map((token) => {
      return {
        tokenId: token.Token ?? -1,
        profile: token.Profile ?? {},
        uri: token.Uri ?? "",
        contractAddress: token.Contract ?? "",
      };
    });
  });
};
